import { css } from 'styled-components'

/* tiptap form 과 tiptap으로 html 작성된 style */
const wiswigContent = css`
  .--text-content {
    white-space: break-spaces;
    word-break: break-word;
    line-height: 1.5;
    letter-spacing: 0.3px;

    > * {
      color: inherit;
    }

    a {
      text-decoration: none;
      background-image: linear-gradient(var(--color-primary), var(--color-primary));
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      transition: background-size 0.2s;
      word-break: break-all;

      /* @media (hover: hover) and (pointer: fine) {
      :hover {
        background-size: 100% 20%;
      }
    } */

      &.--text-content__asset,
      &.--text-content__user {
        background-image: inherit;
      }
    }
  }

  .--text-content__image {
    display: block;
    margin: var(--spacing-legacy-15) auto;
    max-width: 100%;
    max-height: 64rem;
    object-fit: cover;
    border-radius: var(--border-radius-12);

    @media (max-width: 767px) {
      max-height: fit-content;
    }
  }

  .--portfolio-content {
    margin: var(--spacing-legacy-15) auto;
    width: 70%;
    border: 1px solid var(--color-gray-19);

    @media (max-width: 767px) {
      width: auto;
    }
  }

  .--portfolio-content.selected,
  .--text-content__image.ProseMirror-selectednode {
    border: 1px solid var(--color-white);
    border-radius: var(--border-radius-12);
  }

  .--text-content__asset {
    color: var(--color-primary);
    font-weight: 700;
  }

  .--text-content__user {
    color: var(--color-primary);
    background-color: var(--color-primary-a2);
    line-height: 1.5;
    display: inline-block;
  }
`

export default wiswigContent
