import { css } from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const spacing = css`
  --spacing-legacy-1: 0.1rem;
  --spacing-legacy-2: 0.2rem;
  --spacing-legacy-3: 0.3rem;
  --spacing-legacy-4: 0.4rem;
  --spacing-legacy-5: 0.5rem;
  --spacing-legacy-6: 0.6rem;
  --spacing-legacy-8: 0.8rem;
  --spacing-legacy-9: 0.9rem;
  --spacing-legacy-10: 1rem;
  --spacing-legacy-12: 1.2rem;
  --spacing-legacy-13: 1.3rem;
  --spacing-legacy-14: 1.4rem;
  --spacing-legacy-15: 1.5rem;
  --spacing-legacy-16: 1.6rem;
  --spacing-legacy-17: 1.7rem;
  --spacing-legacy-18: 1.8rem;
  --spacing-legacy-19: 1.9rem;
  --spacing-legacy-20: 2rem;
  --spacing-legacy-21: 2.1rem;
  --spacing-legacy-22: 2.2rem;
  --spacing-legacy-23: 2.3rem;
  --spacing-legacy-24: 2.4rem;
  --spacing-legacy-25: 2.5rem;
  --spacing-legacy-26: 2.6rem;
  --spacing-legacy-27: 2.7rem;
  --spacing-legacy-28: 2.8rem;
  --spacing-legacy-29: 2.9rem;
  --spacing-legacy-30: 3rem;
  --spacing-legacy-32: 3.2rem;
  --spacing-legacy-34: 3.4rem;
  --spacing-legacy-35: 3.5rem;
  --spacing-legacy-38: 3.8rem;
  --spacing-legacy-40: 4rem;
  --spacing-legacy-42: 4.2rem;
  --spacing-legacy-44: 4.4rem;
  --spacing-legacy-45: 4.5rem;
  --spacing-legacy-48: 4.8rem;
  --spacing-legacy-50: 5rem;
  --spacing-legacy-52: 5.2rem;
  --spacing-legacy-53: 5.3rem;
  --spacing-legacy-56: 5.6rem;
  --spacing-legacy-57: 5.7rem;
  --spacing-legacy-58: 5.8rem;
  --spacing-legacy-60: 6rem;
  --spacing-legacy-64: 6.4rem;
  --spacing-legacy-68: 6.8rem;
  --spacing-legacy-72: 7.2rem;
  --spacing-legacy-80: 8rem;
  --spacing-legacy-120: 12rem;
  --spacing-legacy-140: 14rem;
  --spacing-legacy-200: 20rem;
  --spacing-legacy-240: 24rem;
  --spacing-legacy-300: 30rem;
  --spacing-legacy-320: 32rem;

  @media (max-width: 767px) {
    --spacing-legacy-1: ${getSize(1)};
    --spacing-legacy-2: ${getSize(2)};
    --spacing-legacy-3: ${getSize(3)};
    --spacing-legacy-4: ${getSize(4)};
    --spacing-legacy-5: ${getSize(5)};
    --spacing-legacy-6: ${getSize(6)};
    --spacing-legacy-8: ${getSize(8)};
    --spacing-legacy-9: ${getSize(9)};
    --spacing-legacy-10: ${getSize(10)};
    --spacing-legacy-12: ${getSize(12)};
    --spacing-legacy-13: ${getSize(13)};
    --spacing-legacy-14: ${getSize(14)};
    --spacing-legacy-15: ${getSize(15)};
    --spacing-legacy-16: ${getSize(16)};
    --spacing-legacy-17: ${getSize(17)};
    --spacing-legacy-18: ${getSize(18)};
    --spacing-legacy-19: ${getSize(19)};
    --spacing-legacy-20: ${getSize(20)};
    --spacing-legacy-21: ${getSize(21)};
    --spacing-legacy-22: ${getSize(22)};
    --spacing-legacy-23: ${getSize(23)};
    --spacing-legacy-24: ${getSize(24)};
    --spacing-legacy-25: ${getSize(25)};
    --spacing-legacy-26: ${getSize(26)};
    --spacing-legacy-27: ${getSize(27)};
    --spacing-legacy-28: ${getSize(28)};
    --spacing-legacy-29: ${getSize(29)};
    --spacing-legacy-30: ${getSize(30)};
    --spacing-legacy-32: ${getSize(32)};
    --spacing-legacy-34: ${getSize(34)};
    --spacing-legacy-35: ${getSize(35)};
    --spacing-legacy-38: ${getSize(38)};
    --spacing-legacy-40: ${getSize(40)};
    --spacing-legacy-42: ${getSize(42)};
    --spacing-legacy-44: ${getSize(44)};
    --spacing-legacy-45: ${getSize(45)};
    --spacing-legacy-48: ${getSize(48)};
    --spacing-legacy-50: ${getSize(50)};
    --spacing-legacy-52: ${getSize(52)};
    --spacing-legacy-53: ${getSize(53)};
    --spacing-legacy-56: ${getSize(56)};
    --spacing-legacy-57: ${getSize(57)};
    --spacing-legacy-58: ${getSize(58)};
    --spacing-legacy-60: ${getSize(60)};
    --spacing-legacy-64: ${getSize(64)};
    --spacing-legacy-68: ${getSize(68)};
    --spacing-legacy-72: ${getSize(72)};
    --spacing-legacy-80: ${getSize(80)};
    --spacing-legacy-120: ${getSize(120)};
    --spacing-legacy-140: ${getSize(140)};
    --spacing-legacy-200: ${getSize(200)};
    --spacing-legacy-240: ${getSize(240)};
    --spacing-legacy-300: ${getSize(300)};
    --spacing-legacy-320: ${getSize(320)};
  }
`

export default spacing
