import { css } from 'styled-components'

export const zIndex = {
  highest: 100000000,
}

const layout = css`
  --z-1: 1; // 캐로셀 네비 버튼, 포폴 자산 컨트롤러(정렬 등), 포폴 차트 전체 자산 보기, 모달 nestedPage
  --z-10: 10; // 배당 캘린더 목록 헤더
  --z-100: 100; // 포폴 comment, 페이지 헤더 검색 목록, 캘린더 데이트 피커
  --z-1000: 1000; // 포폴 자산 편집 모달, 상단 페이지 전환 로딩바
  --z-1001: 1001; // 커뮤니티 글 작성 아이콘, 스크롤 최상단 이동 아이콘
  /* 10000000 채널톡 */
  --z-10000001: 10000001; // react-modal
  --z-10000002: 10000002; // loading 컴포넌트
  --z-100000000: ${zIndex.highest}; // toast, tippy 가 사용된 tooltip 과 MoreMenu

  --contents-width: 1100px;
  --community-width: 76rem;

  @media (max-width: 767px) {
    --contents-width: 100%;
    --community-width: 100%;
  }
`

export const layoutPreset = css`
  .--flex {
    display: flex;
  }

  .--flex-column {
    display: flex;
    flex-direction: column;
  }

  .--flex-column-x-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .--inline-flex-x-center {
    display: inline-flex;
    align-items: center;
  }

  .--flex-x-y-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .--flex-x-center {
    display: flex;
    align-items: center;
  }

  .--flex-x-start {
    display: flex;
    align-items: flex-start;
  }

  .--flex-x-end {
    display: flex;
    align-items: flex-end;
  }

  .--flex-y-end {
    display: flex;
    justify-content: flex-end;
  }

  .--flex-y-center {
    display: flex;
    justify-content: center;
  }

  .--flex-y-between {
    display: flex;
    justify-content: space-between;
  }

  .--flex-y-between-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .--flex-x-y-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .--flex-wrap {
    flex-wrap: wrap;
  }

  .--relative {
    position: relative;
  }

  /* 부모가 높이를 지녀야함 */
  /* 부모 position: absolute 일때 주의 */
  .--sticky {
    position: sticky;
    top: 0;
    z-index: var(--z-1);
  }

  .--margin-left-auto {
    margin-left: auto;
  }

  .--margin-top-auto {
    margin-top: auto;
  }

  /* content 너비만큼 유지 */
  .--flex-shrink-off {
    flex-shrink: 0;
  }
`

export default layout
