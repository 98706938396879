import { css } from 'styled-components'

const preset = css`
  .--absolute-contentful {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .--primary-text-bg {
    color: black;
    background-color: var(--color-primary);
  }

  .--primary-text-border {
    border: 1px solid rgba(245, 168, 26, 0.4);
    border-radius: 6px;
    padding: 6px 8px;
    color: var(--color-primary);
  }

  /* horizontal-scrolling-wrapper */
  .--horizontal-scroll {
    display: flex;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  /* 모바일 횡스크롤 */
  .--mobile-horizontal-scroll {
    @media (max-width: 767px) {
      display: flex;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }

      .--mobile-horizontal-scroll-item {
        margin-left: var(--spacing-legacy-10);

        :last-child {
          margin-right: var(--spacing-legacy-10);
        }
      }
    }
  }

  /* 텍스트 셀렉션 방지 */
  .--none-select {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }

  /* close X */
  .--close {
    position: relative;
    width: 100%;
    height: 100%;
    background: none;
    border: 0;
    opacity: 0.5;

    :hover {
      opacity: 1;
    }
    :before,
    :after {
      position: absolute;
      top: 0;
      content: ' ';
      height: 100%;
      width: 1px;
      background-color: white;
    }
    :before {
      transform: rotate(45deg);
    }
    :after {
      transform: rotate(-45deg);
    }
  }

  .--blind {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  .--ani-slide-in {
    animation: slidein 0.2s ease 1 normal;

    @keyframes slidein {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .--ani-tilt-shake {
    animation: tilt-shaking 0.2s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    animation-iteration-count: 2;

    @keyframes tilt-shaking {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(2deg);
      }
      50% {
        transform: rotate(0eg);
      }
      75% {
        transform: rotate(-2deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }

  .--after-check-mark {
    ::after {
      content: '';
      display: inline-block;
      border-bottom: 1px solid var(--color-gray-128);
      border-left: 1px solid var(--color-gray-128);
      width: var(--spacing-legacy-8);
      height: var(--spacing-legacy-5);
      transform: translate(var(--spacing-legacy-6), 100%) rotate(-45deg);
    }
  }

  .--right-arrow {
    ::after {
      content: '';
      display: inline-block;
      margin-left: auto;
      border-top: 1px solid var(--color-gray-128);
      border-right: 1px solid var(--color-gray-128);
      width: var(--spacing-legacy-10);
      height: var(--spacing-legacy-10);
      transform: rotate(45deg);
    }
  }

  .--left-arrow {
    ::before {
      content: '';
      display: inline-block;
      border-top: 1px solid var(--color-gray-128);
      border-right: 1px solid var(--color-gray-128);
      width: var(--spacing-legacy-10);
      height: var(--spacing-legacy-10);
      transform: rotate(-135deg);
    }
  }

  .--desktop-only {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .--mobile-only {
    @media (min-width: 768px) {
      display: none;
    }
  }
`

export default preset
