import { css } from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const borders = css`
  --border-radius-4: 0.4rem;
  --border-radius-6: 0.6rem;
  --border-radius-8: 0.8rem;
  --border-radius-12: 1.2rem;
  --border-radius-15: 1.5rem;
  --border-radius-16: 1.6rem;
  --border-radius-18: 1.8rem;
  --border-radius-20: 2rem;
  --border-radius-24: 2.4rem;
  --border-radius-30: 3rem;
  --border-radius-34: 3.4rem;
  --border-radius-40: 4rem;

  @media (max-width: 767px) {
    --border-radius-4: ${getSize(4)};
    --border-radius-6: ${getSize(6)};
    --border-radius-8: ${getSize(8)};
    --border-radius-12: ${getSize(12)};
    --border-radius-15: ${getSize(15)};
    --border-radius-16: ${getSize(16)};
    --border-radius-18: ${getSize(18)};
    --border-radius-20: ${getSize(20)};
    --border-radius-24: ${getSize(24)};
    --border-radius-30: ${getSize(30)};
    --border-radius-40: ${getSize(40)};
  }
`

export default borders
